<template>
  <div class="card mb-5 mb-xl-12">
    <div class="card-header border-0 cursor-pointer" role="button" data-bs-toggle="collapse" data-bs-target="#kt_account_profile_details" aria-expanded="true" aria-controls="kt_account_profile_details">
      
      <div class="card-title m-0">
        <h3 class="fw-bolder m-0">Satışlar</h3>

        
      </div>      
    </div>

    
    
    
    <div id="kt_account_profile_details" class="collapse show">

       

        <div class="card-body border-top p-9">

            <form action="">

            <div class="d-flex border-bottom pb-9 mb-9 align-items-end">
                <div class="me-4">
                    Ödeme Tipleri
                    <select class="form-select" name="" v-model="paymentType" id="">
                        <option :value="i" v-for="(item,i) in paymentTypes" :key="i">{{item}}</option>
                    </select>
                </div>

                <div class="me-4">
                    Tarih Aralığı
                    <div class="d-flex">
                        <input class="form-control me-2" v-model="dateRange.start" type="date">
                        <input class="form-control" v-model="dateRange.end" type="date">
                    </div>
                </div>

                <div class="me-4">
                    Arama
                    <div class="d-flex">
                        <input type="text" class="form-control me-2" v-model="searchWord">
                    </div>
                </div>



                

                <div class="me-4">
                    <input @click.prevent="filter()" type="submit" class="btn btn-info" value="Filtere">
                </div>
            </div>

            </form>

            <div class="table-responsive" v-if="sales">

              <table class="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                <thead>
                    <tr class="fw-bolder">
                    <th>Danışan</th>
                    <th>Ürün</th>                    
                    <th>Ödeme Tipi</th>
                    <th>Fiyat/Puan</th>
                    <th>Kazanılan Puan</th>
                    <th>Satın Alma Tarihi</th>
                    <th>Bitiş Tarihi</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, i) in sales" :key="i">
                    <td>
                        <div class="d-flex align-items-center">
                            <div class="d-flex justify-content-start flex-column">
                                <a target="_blank" :href="'client/'+item.userId+'/calls'" class="text-dark fw-bolder text-hover-primary fs-6">
                                    {{ item.userName }}  {{ item.userSurName }}
                                </a>
                                <span class="text-muted fw-bold text-muted d-block fs-7">
                                    <div class="d-flex align-items-center">
                                        <span>{{item.userEmail}}</span>
                                    </div>
                                </span>
                            </div>
                        </div>
                    </td>
                    
                    <td>{{item.product}}</td>
                    <td>{{item.paymentType}}</td>
                    <td>{{item.price}}</td>
                    <td>{{item.earnedScore}}</td>
                    <td>{{moment(item.purchaseDate).format('DD MMMM YYYY HH:mm')}}</td>
                    <td>
                        <div>
                            {{moment(item.expireDate).format('DD MMMM YYYY HH:mm')}}
                            <div>
                                <span class="badge badge-light-info" v-if="!dateIsPast(item.expireDate)">
                                    {{moment(item.expireDate).diff(moment(),'days')}} gün kaldı
                                </span>

                                <span class="badge badge-light-danger" v-if="dateIsPast(item.expireDate)">
                                    Süre bitti
                                </span>
                            </div>
                        </div>
                            
                        
                        
                    </td>
                    
                    </tr>            
                </tbody>
              </table>

               <paging 
                v-if="recordcount>0" 
                :page="page" 
                :pagelength="pageLength" 
                :recordcount="recordcount" 
                @next="next" 
                @prev="prev"
                @gotoPage="gotoPage($event)"
                />
      
            </div>
        </div>
    </div>
    
        
    
  </div>


</template>



<script>
import api from '@/core/services/ApiService'
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.js";
import moment from 'moment'
import Paging from '@/components/BD/Paging.vue'

export default  {
  name:'Calls',
  components:{
      Paging
  },

  data(){
    return {
        dateRange:{
            start:null,
            end:null
        },
        
        ticketStatusses:{
            'JJ': {title:'Tümü','filtername':'Tümü','class':'warning'},
            'PE': {title:'Bekliyor','filtername':'Bekleyen','class':'warning'},
            'AC': {title:'Aktif','filtername':'Aktif','class':'info'},
            'CA': {title:'İptal Edildi','filtername':'İptal','class':'danger'},
            'DA': {title:'Tamamlandı','filtername':'Tamamlandı','class':'success'},
            'OP': {title:'Açık','class':'info'},
            'NO': {title:'Açık Değil','class':'info'},
        },
        consultantType:0,
        consultantTypes:null,

        paymentType:0,
        ticketStatus:'JJ',
        paymentTypes:{
            0: 'Hepsi',
            1: 'Kredi Kartı',
            2: 'Paket',
            3: 'Puan'
        },
        recordcount:0,
        statusses : {
        class:{
          'AC':'success',
          'DA':'danger'
        },
        label:{
          'AC':'Aktif',
          'DA':'Pasif'

        }
      },
      dateStatusses:{
          0:'success',
          1:'danger'
        },
        sales:[],
        page:1,
        pageLength:10,

        searchWord:''
    }
  },

  
    
    watch:{
      page:function(){
        this.getList();
      }
    },

  methods:{
      dateIsPast(date){
          if(moment(date)<moment()) return 1;
          return 0;
      },
      changeTicketType(){
          if(this.ticketType==1){
              this.consultantType = 0;
          }

      },
      filter(){
          this.getList();
      },
      duration(time){
          let p = time.split(":");
          let hours = parseInt(p[0]);
          let minutes = parseInt(p[1]);
          let seconds = parseInt(p[2]);

          return (hours > 0 ? hours + " sa. " : '') + (minutes > 0 ? minutes + " dk. " :'')  + (seconds > 0 ? seconds + " sn." :'');
      },

      moment:function(date){
          return moment(date)
      },

      next(){
        this.page++;
      },

      prev(){
        if(this.page>1){
          this.page--;
        }
      },

      gotoPage(pageNo){
        this.$router.push('?page='+pageNo);        
        this.page = pageNo;
      },

      getList(){
          let searchWord = this.searchWord=='' ? '-' : this.searchWord;
          api.get("/User/ListPurchase/"+this.paymentType+"/"+this.dateRange.start+"/"+this.dateRange.end+"/"+this.pageLength+"/"+this.page+"/"+searchWord).then((res)=>{
              this.recordcount = res.data.rowCount;
              this.sales = res.data.searchData;
          });
           
      },

      
  },


  mounted(){
      let storedDateRange = window.localStorage.getItem("YTMReportDateRange");
      if(!storedDateRange){
       this.dateRange = {
           start:moment().subtract(1,'months').format('YYYY-MM-DD'),
           end:moment().format('YYYY-MM-DD'),
       }     
      }

      //http://90.159.30.197:9003/          VCalls/ListTickets/0/JJ/0/2022-07-05/2022-08-11/10/1/-
      //https://boapi.ytmdanismanlik.com/   VCalls/ListTickets/0/JJ/0/2022-07-05/2022-08-11/10/1/-/

     

     setCurrentPageBreadcrumbs("Görüşme Listesi", ["Ayarlar","Görüşmeler"]);
    this.getList();
    api.get('User/ListDoctorType/JJ').then((res)=>{
        this.consultantTypes = res.data;
    })

   
  },

}

</script>
<style scoped>
input:disabled{cursor: not-allowed; background-color: #eee;}
</style>

